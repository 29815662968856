<template>
	<div class="profile">
		<div class="profile__img" :style="{'background-image': 'url(' + profile.img + ')'}"></div>

		<div class="profile__details">
			<div class="profile__name">
				<span class="profile__status">Online</span>
				{{ profile.name }}
			</div>

			<table class="profile__info">
				<tr>
					<td>Age:</td>
					<td>{{ profile.age }}</td>
				</tr>
				<tr>
					<td>Country:</td>
					<td>{{ profile.country }}</td>
				</tr>
				<tr>
					<td>City:</td>
					<td>{{ profile.city }}</td>
				</tr>
				<tr>
					<td>Height:</td>
					<td>{{ profile.height }}</td>
				</tr>
				<tr>
					<td>Eyes color:</td>
					<td>{{ profile.eyes_color }}</td>
				</tr>
			</table>

			<div class="profile__heading">About me</div>

			<p v-text="profile.about"></p>
		</div>
	</div>
</template>

<script>
export default {
	name: 'profile',

	props: {
		profile: {
			type: Object,
			default () { return {}; }
		}
	}
}
</script>

<style lang="scss">
.profile
{
	position: relative;
	display: none;
	margin: auto;
	max-width: 620px;
	overflow: hidden;
	background: #FFF;
	box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.5);
	border-radius: 15px;

	&__img
	{
		float: left;
		width: 50%;
		height: 100%;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
	}

	&__details
	{
		position: relative;
		height: 100%;
		max-height: 520px;
		margin-left: 50%;
		padding: 0 30px;
		border-top: 35px solid transparent;
		border-bottom: 35px solid transparent;
		overflow-y: auto;
	}

	&__name
	{
		position: sticky;
		top: 0;
		padding-bottom: 10px;
		line-height: 26px;
		font-size: 2.4rem;
		font-weight: bold;
		background: #FFF;
		z-index: 5;
	}

	&__status
	{
		float: right;
		margin-left: 5px;
		padding: 0 5px;
		font-size: 1.4rem;
		font-weight: 500;
		color: #FFF;
		background: #07CA9B;
		border-radius: 5px;
	}

	&__info
	{
		width: 100%;
		margin-top: 15px;
		margin-bottom: 25px;
		line-height: 1.2;

		tr
		{
			&:nth-of-type(2n+1)
			{
				background-color: #F6F9FA;
			}
		}

		td
		{
			height: 35px;
			padding-right: 15px;

			&:first-child
			{
				padding-left: 15px;
				font-weight: 500;
			}
		}
	}

	&__heading
	{
		margin-bottom: 10px;
		line-height: 1;
		font-size: 18px;
		font-weight: 500;
	}

	@media (min-width: 1200px)
	{
		display: block;
	}
}
</style>
